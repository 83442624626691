import Container from '@/components/Container'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useFormQuery } from '@/queries/useFormQuery'
import { FormSectionContextProvider } from '@/pages/Home/context/FormSectionContext'
import { Store } from '@/store/type'
import { useBaseStore } from '@/store'
import UserRegistrationSection from '@/pages/Home/components/UserRegistrationSection'
import PageLoader from '@/pages/PageLoader'
import { Skeleton } from '@/components/ui/skeleton'
import {
    Carousel,
    CarouselApi,
    CarouselContent,
    CarouselItem,
} from '@/components/ui/carousel'
import FormField from '../FormField'
import { Progress } from '@/components/ui/progress'
import { FieldStateData, FieldType } from '@/store/formSlice/type'
import { CheckCircle2, Crown, Paintbrush } from 'lucide-react'
import { TypographyMuted, TypographyP } from '@/components/ui/typography'
import CarouselPrevious from './components/CarouselPrevious'
import CarouselNext from './components/CarouselNext'
import { Button } from '@/components/ui/button'
import { useMutation } from '@tanstack/react-query'
import { answerForm } from '@/api/business/form'
import { toast } from 'sonner'
import { getError } from '@/utils/getError'

const stateSelector = (state: Store) => ({
    formState: state.formSlice.state.formState,
    user: state.formSlice.state.user,
    setInitialValues: state.formSlice.actions.setInitialValues,
    setShouldValidate: state.formSlice.actions.setShouldValidate,
    onReset: state.formSlice.actions.onReset,
})

const FormData = () => {
    const { setInitialValues, formState, onReset, setShouldValidate, user } =
        useBaseStore(stateSelector)
    const { data = [] } = useFormQuery({ enabled: true })
    const [api, setApi] = useState<CarouselApi>()
    const [count, setCount] = useState(0)
    const [current, setCurrent] = useState(0)
    const [activeField, setActiveField] = useState<FieldStateData | undefined>()

    const onResetForm = useCallback(() => {
        api?.scrollTo(0)
        onReset()
    }, [api, onReset])

    const { mutate, isPending } = useMutation({
        mutationFn: answerForm,
        onSuccess: () => {
            toast.success('Respostas enviadas com sucesso')
            onResetForm()
        },
        onError: (err) => {
            const message = getError(err, 'Erro ao responder formulário')

            toast.error(message)
        },
    })

    const answers = useMemo(() => {
        return Object.values(formState).map((sectionState) => {
            return {
                ...sectionState,
                fieldState: Object.values(sectionState.fieldState),
            }
        })
    }, [formState])

    const requiredFields = useMemo(() => {
        return Object.values(formState).flatMap((sectionState) => {
            return Object.values(sectionState.fieldState).filter(
                (field) => field.isRequired
            )
        })
    }, [formState])

    const answeredFields = useMemo(
        () =>
            requiredFields.filter(
                (field) =>
                    (Array.isArray(field.value) && field.value.length > 0) ||
                    !!field.value
            ),
        [requiredFields]
    )

    const canSubmit = requiredFields.length === answeredFields.length

    const onSubmit = useCallback(() => {
        setShouldValidate(false)

        if (!user.isValid)
            return toast.error(
                'Colaborador inválido. Insira uma matrícula válida'
            )

        const hasError =
            answers.filter((section) =>
                section.fieldState.some(
                    (field) =>
                        field.isRequired &&
                        (!field.value ||
                            (Array.isArray(field.value) &&
                                field.value.length === 0))
                )
            ).length > 0

        if (hasError) {
            setShouldValidate(true)
            return toast.error('Preencha todos os campos')
        }

        const response = answers.flatMap((answer) => {
            return answer.fieldState
                .filter((field) => !!field.value)
                .map((field) => {
                    if (field.type === FieldType.options) {
                        return {
                            type: 'optionResponse' as const,
                            sectionId: answer.sectionId,
                            fieldId: field.fieldId,
                            value: null,
                            options: (field?.value as number[])?.map(
                                (option) => ({
                                    optionId: option,
                                })
                            ),
                        }
                    }

                    return {
                        type: 'response' as const,
                        sectionId: answer.sectionId,
                        fieldId: field.fieldId,
                        value: field.value as string,
                    }
                })
        })

        mutate({
            userRegistration: user.userRegistration,
            response,
        })
    }, [answers, user, mutate, setShouldValidate])

    let canGoNext = true

    if (activeField?.isRequired) {
        if (Array.isArray(activeField.value)) {
            canGoNext = activeField.value.length > 0
        } else {
            canGoNext = !!activeField.value
        }
    }

    useEffect(() => {
        if (data.length > 0) setInitialValues(data)
    }, [data, setInitialValues])

    useEffect(() => {
        if (!api) {
            return
        }

        const getActiveField = (idx: number) => {
            const activeField = data.flatMap((section) =>
                section.fields.map((field) => ({
                    section,
                    ...field,
                }))
            )[idx]

            if (activeField)
                setActiveField(
                    formState[activeField.section.id].fieldState[activeField.id]
                )

            setCurrent(api.selectedScrollSnap() + 1)
        }

        setCount(api.scrollSnapList().length)

        getActiveField(api.selectedScrollSnap())

        api.on('select', () => {
            getActiveField(api.selectedScrollSnap())
        })
    }, [api, formState, data])

    return (
        <>
            <div className="flex justify-between gap-2">
                <UserRegistrationSection />
                <div className="space-x-2">
                    <Button
                        variant="ghost"
                        onClick={onResetForm}
                        className="gap-2"
                    >
                        <Paintbrush size={14} />
                        Limpar respostas
                    </Button>
                </div>
            </div>
            <div className="flex-1">
                <Carousel
                    className="relative"
                    setApi={setApi}
                    canScrollNextItem={canGoNext}
                    opts={{
                        watchDrag: false,
                    }}
                >
                    <CarouselContent>
                        {data.map((section) => {
                            return (
                                <FormSectionContextProvider
                                    key={section.id}
                                    sectionId={section.id}
                                >
                                    {section.fields.map((field) => {
                                        return (
                                            <CarouselItem
                                                key={`${section.id}-${field.id}`}
                                                className="space-y-4"
                                            >
                                                <div
                                                    key={section.id}
                                                    className="relative flex items-center justify-between overflow-hidden"
                                                >
                                                    <div className="flex flex-col justify-center pl-10 space-y-0.5">
                                                        <TypographyP className="z-10 font-semibold md:text-xl text-primary">
                                                            {section.title}
                                                        </TypographyP>
                                                        {section.description && (
                                                            <TypographyMuted className="z-10 text-sm md:text-md">
                                                                {
                                                                    section.description
                                                                }
                                                            </TypographyMuted>
                                                        )}
                                                        <Crown
                                                            size={60}
                                                            className="absolute z-0 -translate-y-1/2 -left-6 top-1/2 text-primary/50"
                                                        />
                                                    </div>
                                                    <TypographyMuted>
                                                        <span className="font-bold text-primary">
                                                            {current}
                                                        </span>
                                                        /{count}
                                                    </TypographyMuted>
                                                </div>
                                                <FormField formField={field} />
                                            </CarouselItem>
                                        )
                                    })}
                                </FormSectionContextProvider>
                            )
                        })}
                    </CarouselContent>
                    <Progress
                        value={
                            (answeredFields.length / requiredFields.length) *
                            100
                        }
                    />
                    <div className="flex flex-col gap-2 pt-4">
                        <div className="flex items-center gap-2 md:justify-between">
                            <CarouselPrevious />
                            {count === current ? (
                                <Button
                                    disabled={!canSubmit || isPending}
                                    className="flex-1 gap-2 bg-blue-500 hover:bg-blue-600/90"
                                    onClick={onSubmit}
                                >
                                    <CheckCircle2 size={16} />
                                    Enviar
                                </Button>
                            ) : (
                                <CarouselNext />
                            )}
                        </div>
                    </div>
                </Carousel>
            </div>
        </>
    )
}

const FormSkeleton = () => {
    return (
        <div className="space-y-8">
            <Skeleton className="h-[150px] w-full" />
            <Skeleton className="h-[360px] w-full" />
            <div className="p-4" />
        </div>
    )
}

const Form = () => {
    const { isPending, error } = useFormQuery({ enabled: true })

    return (
        <Container
            data-form
            className="relative p-4 space-y-4 md:max-w-[960px] gap-8"
        >
            <PageLoader
                isLoading={isPending}
                error={error}
                fallback={<FormSkeleton />}
            >
                <FormData />
            </PageLoader>
        </Container>
    )
}

export default Form
